
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const formData = ref({
      coupon_name: "",
      user_id: "",
      discount: "",
    });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const users = ref<any>(false);
    const page = ref<string>("");

    // const value = ref('');
    const registration = Yup.object().shape({
      coupon_name: Yup.string().required().label("Name"),
      user_id: Yup.string().required().label("User Name"),
      discount: Yup.number()
        .typeError("Please Specify number")
        .required()
        .label("Discount"),
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        // getUser(id);
        getUserData();
        getCoupon(data);
        // page.value = "Edit";
        setCurrentPageBreadcrumbs("Edit Coupon", ["Coupon"]);
      } else {
        // page.value = "Add";
        getUserData();
        setCurrentPageBreadcrumbs("Add Coupon", ["Coupon"]);
      }

      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        //formData.value = data
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        // getUser(id);
        page.value = "Edit";
      } else {
        page.value = "Add";
      }
    });
    const getCoupon = (id) => {
      var request = {
        url: `coupon/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log(data)
          formData.value = {
            coupon_name: data.data.coupon_name,
            user_id: data.data.user_id,
            discount: data.data.discount,
          };
        }
      });
    };
    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing errors

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      console.log("values", values);

      var request = {
        url: `coupon/${route.params.id}`,
        data: values,
      };
      store
        .dispatch(Actions.PUT, request)
        .then((data) => {
          if (data) {
            Swal.fire("Success", "Coupon updated successfully!", "success");
            router.push({ name: "admin-coupon" });
          }
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      // var request = {
      //   url: 'profile/changePassword',
      //   data: {
      //     old_password: values.current_password,
      //     new_password: values.password,
      //     user_id: profile.value._id
      //   }
      // }
      // Dummy delay
      // setTimeout(() => {

      // loading.value = true;
      // var request = {
      //   url: "coupons",
      //   data: {
      //       coupon_name: values.coupon_name,
      //       user_id: values.user_id,
      //       discount: values.discount,
      //   },
      // };
      // store
      // .dispatch(Actions.POST, request)
      // .then((data) => {
      //   if (data) {
      //     Swal.fire("Success", "Coupon added successfully!", "success");
      //     router.push({ name: "admin-coupon" });
      //   }
      //   loading.value = false;
      // })
      // .catch(() => {
      //   const [error] = Object.keys(store.getters.getErrors);
      //   Swal.fire({
      //     text: store.getters.getErrors[error],
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Try again!",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-danger",
      //     },
      //   });
      // });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      // }, 2000);
      // Swal.fire({
      //   text: "Coupon added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-coupon" });
      // });
    };

    const getUserData = () => {
      var request = {
        url: "/users",
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // console.log('data',data);
          users.value = data.data;
        }
      });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      page,
      getUserData,
      users,
      formData,
    };
  },
});
